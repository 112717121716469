<template>
  <div class="card">
    <div class="card-body">
      <h6 class="header-pretitle mb-3">Your Message</h6>
      <div class="message">
        <div class="comment-body">
          <p class="comment-text mt-0" style="white-space: pre-line" v-html="parsedMessageBody"></p>
          <p v-if="parsedPromo" v-html="parsedPromo"></p>
          Sent from {{ activeCompany.name }} <br />Reply STOP to opt-out
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { templatize } from '@/lib/template'
import { createNamespacedHelpers } from 'vuex'
const CampaignModule = createNamespacedHelpers('campaign')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'CampaignMessageBody',
  props: ['campaign'],
  computed: {
    ...CampaignModule.mapState(['location']),
    ...CompanyModule.mapState(['activeCompany']),
    ...UserModule.mapState(['onlineUser']),
    parsedMessageBody() {
      return templatize({
        body: this.campaign.messageBody,
        customer: { name: 'Jane Doe' },
      })
    },
    parsedPromo() {
      if (!this.campaign.promo) return undefined
      return 'Redeem: <span class="text-primary">ovtn.io/redeem</span>'
    },
  },
}
</script>

<style scoped lang="scss">
.message .comment-text:after {
  display: none;
}
</style>
