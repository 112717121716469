<template>
  <div>
    <div class="row mb-2">
      <div class="col-12">
        <div class="form-group">
          <h3 class="mt-2 mb-4">What do you want to call your campaign?</h3>
          <input
            type="text"
            ref="messageName"
            placeholder="20% off after 3pm"
            class="form-control"
            v-model="campaign.title"
            @input="updateTitle"
            v-b-popover.hover.topleft="'This is for your reference, and is not shown to customers'"
            title="Name"
          />
        </div>
      </div>
    </div>
    <div v-if="!campaign._id" class="row">
      <div class="col-12">
        <div class="form-group mb-0">
          <h3 class="mt-2 mb-4">What type of campaign do you want to launch?</h3>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div
                class="card"
                :class="{ active: campaign.type === 'auto' }"
                @click="setType('auto')"
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <a href="#!" class="avatar avatar-lg pt-2">
                        <span class="avatar-title rounded-circle">
                          <i class="fas fa-robot"></i>
                        </span>
                      </a>
                    </div>
                    <div class="col">
                      <!-- Title -->
                      <h4 class="card-title mb-1">
                        <a href="#!">Automated</a>
                      </h4>

                      <!-- Status -->
                      <p class="card-text text-muted small">
                        Campaign will be sent to existing and future customers.
                      </p>
                    </div>

                    <div class="col-auto" v-if="campaign.type === 'auto'">
                      <a href="#!" class="btn btn-sm btn-success btn-rounded-circle">
                        <span class="fe fe-check"></span>
                      </a>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <!-- / .card-body -->
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div
                class="card"
                :class="{ active: campaign.type === 'blast' }"
                @click="setType('blast')"
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <a href="#!" class="avatar avatar-lg pt-2">
                        <span class="avatar-title rounded-circle text">
                          <i class="fas fa-bolt"></i>
                        </span>
                      </a>
                    </div>
                    <div class="col">
                      <!-- Title -->
                      <h4 class="card-title mb-1">
                        <a href="#!">One-time blast</a>
                      </h4>

                      <!-- Status -->
                      <p class="card-text text-muted small">
                        A one-time campaign that only gets sent to existing customers.
                      </p>
                    </div>

                    <div class="col-auto">
                      <a
                        href="#!"
                        class="btn btn-sm btn-success btn-rounded-circle"
                        v-if="campaign.type === 'blast'"
                      >
                        <span class="fe fe-check"></span>
                      </a>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <!-- / .card-body -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('campaign')
export default {
  name: 'CreateCampaignType',
  data: () => ({}),
  computed: {
    ...mapState(['campaign']),
    invalid() {
      if (!this.campaign.title) {
        return 'Be sure to give your campaign a name.'
      }

      if (!this.campaign.type) {
        return 'Be sure to choose a campaign type.'
      }

      return false
    },
  },
  mounted() {
    this.$refs.messageName.focus()
  },
  methods: {
    ...mapMutations(['setType']),
    ...mapMutations(['setTitle']),
    updateTitle(e) {
      this.setTitle(e.target.value)
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
}
.active {
  border-color: var(--success);
}
</style>
