<template>
  <form class="mb-2">
    <div class="row" v-if="campaign.messageBody.length">
      <div class="col">
        <CampaignMessageBody :campaign="campaign" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center px-3">
              <div class="col-auto px-2">
                <h4 class="card-title text-uppercase text-muted mb-4">This Campaign</h4>

                <div class="row align-items-center px-3">
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Recipients</h6>
                    <span class="h1 mb-0">{{ recipientCount | prettiefiedNumber }}</span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">&nbsp;</h6>
                    <span class="fas fa-times text-muted mb-0"></span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Credits</h6>
                    <span class="h1 mb-0">{{ smsCount | prettiefiedNumber }}</span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">&nbsp;</h6>
                    <span class="fas fa-chevron-right text-muted mb-0"></span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Total</h6>
                    <span class="h1 mb-0">{{ messageCount | prettiefiedNumber }}</span>
                  </div>
                </div>
              </div>
              <div class="col-auto px-3">
                <h4 class="card-title text-uppercase text-muted mb-4">&nbsp;</h4>

                <div class="row align-items-center px-3">
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">&nbsp;</h6>
                    <h1 class="fas fa-chevron-right text-muted mb-0"></h1>
                  </div>
                </div>
              </div>

              <div class="col-auto px-2">
                <h4 class="card-title text-uppercase text-muted mb-4">This Month</h4>

                <div class="row align-items-center px-3">
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Credits</h6>
                    <span class="h1 mb-0">
                      {{ totalUsage | prettiefiedNumber }}
                      &nbsp;
                      <span class="h3 text-muted">
                        ({{ totalUsagePercent | prettiefiedNumber }}% of
                        {{ textUsage.totalCount | prettiefiedNumber }} limit)
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-info" v-if="!activeCompany.partner && isTextUsageLimitExceeded">
          This campaign will put you {{ overageMessages | prettiefiedNumber }} credits over your
          limit of {{ activeCompany.textUsageLimit | prettiefiedNumber }} credits, and is estimated
          to cost
          {{ overageCost | currency }}
          in overages.
        </div>
      </div>
    </div>
    <hr class="mb-5" />
    <div class="d-flex align-items-center">
      <a
        v-if="showDraftBtn"
        @click="saveDraft"
        href="javascript:;"
        class="mr-4 btn btn-block btn-lg btn-outline-primary"
        :disabled="draftLoading"
        :class="{ 'is-loading': draftLoading }"
        >Save Draft</a
      >
      <a
        @click.once="launch"
        href="javascript:;"
        class="mt-0 btn btn-block btn-lg btn-primary"
        :disabled="launchLoading"
        :class="{ 'is-loading': launchLoading }"
        >Launch Campaign</a
      >
    </div>
  </form>
</template>

<script>
// import RecipientsCard from './RecipientsCard'
// import TotalMessagesCard from './TotalMessagesCard'
// import MonthlyUsageCard from '@/components/Modules/Campaign/MonthlyUsageCard'
import CampaignMessageBody from '@/components/Modules/Campaign/CampaignMessageBody'
import CampaignService from '@/services/CampaignService'
import { createNamespacedHelpers } from 'vuex'
import chrono from 'chrono-node'
import { cloneDeep } from 'lodash-es'
import { campaignStatus } from '@/lib/modules/campaign'

const CustomerModule = createNamespacedHelpers('customer')
const CampaignModule = createNamespacedHelpers('campaign')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CreateCampaignSummary',
  components: {
    // MonthlyUsageCard,
    // RecipientsCard,
    // TotalMessagesCard,
    CampaignMessageBody,
  },
  filters: {
    prettiefiedNumber(value) {
      // if (!typeof value !== Number && typeof value !== String) return '0'
      return String(value)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },

  data: () => ({
    launchLoading: false,
    draftLoading: false,
    campaignStatus: campaignStatus,
  }),
  computed: {
    ...CustomerModule.mapState({ recipientCount: 'count' }),
    ...CustomerModule.mapState(['listFilters']),
    ...CampaignModule.mapState(['campaign']),
    ...CampaignModule.mapGetters(['smsCount']),
    ...CompanyModule.mapState(['activeCompany', 'textUsage']),
    messageCount() {
      return (this.smsCount || 0) * this.recipientCount || 0
    },
    totalUsage() {
      return this.textUsage.textCount + this.messageCount
    },
    totalUsagePercent() {
      return parseInt(((this.totalUsage / this.textUsage.totalCount) * 100).toFixed(), 10) || 0
    },
    overageCost() {
      return this.overageMessages * this.activeCompany.textOverageCost
    },
    overageMessages() {
      return this.textUsage.textCount + this.messageCount - this.textUsage.totalCount
    },
    isTextUsageLimitExceeded() {
      return this.overageMessages > 0
    },
    showDraftBtn() {
      return !this.campaign._id || this.campaign.status === 'draft'
    },
  },
  watch: {
    messageCount: function (value) {
      this.setEstimatedCreditUsage(value)
    },
  },
  methods: {
    ...CampaignModule.mapMutations(['setFilters', 'resetCampaign']),
    ...CompanyModule.mapActions(['queryTextUsage']),
    ...CampaignModule.mapActions(['setEstimatedCreditUsage']),

    async saveDraft() {
      this.draftLoading = true
      this.campaign.status = 'draft'
      try {
        await this.saveOrUpdateCampaign()
      } finally {
        this.draftLoading = false
      }
    },

    launch() {
      this.launchLoading = true
      try {
        this.saveOrUpdateCampaign(true).then(() => (this.launchLoading = false))
      } finally {
        this.launchLoading = false
      }
    },

    async saveOrUpdateCampaign(launched = false) {
      this.setFilters(this.listFilters)

      const campaign = this.parseCampaign(this.campaign)

      let response = ''
      let title = 'Launched!'
      let msgText = `Your campaign is ready to go.`
      try {
        // If we are updating an existing auto campaign
        if (campaign._id) {
          response = await CampaignService.update({
            campaignId: campaign._id,
            fields: {
              title: campaign.title,
              messageBody: campaign.messageBody,
              messageMedia: campaign.messageMedia,
              promo: campaign.promo || null,
              status: launched && campaign.status == 'draft' ? '' : campaign.status,
              'filters.surveyRatings': campaign.filters.surveyRatings,
              'filters.locations': campaign.filters.locations,
              'filters.dateSettings': { ...campaign.filters.dateSettings },
              'filters.actions': campaign.filters.actions,
              'filters.tags': campaign.filters.tags,
              'filters.cqAnswerData': campaign.filters.cqAnswerData,
              scheduling: {
                delay: campaign.scheduling.delay,
                delayLength: campaign.scheduling.delayLength,
                delayUnit: campaign.scheduling.delayUnit,
                autoEnd: campaign.scheduling.autoEnd,
                autoEndDate: campaign.scheduling.autoEndDate,
                blastScheduleDate: campaign.scheduling.blastScheduleDate,
                blastSchedule: campaign.scheduling.blastSchedule,
              },
              estimatedCreditUsage: campaign.estimatedCreditUsage,
            },
          })
        } else {
          // Otherwise create a new campaign
          response = await CampaignService.create({
            campaign: {
              ...campaign,
              company: this.activeCompany,
            },
          })
        }

        this.resetCampaign()

        if (response.body.campaignStatus == campaignStatus.DRAFT) {
          title = 'Draft Saved!'
          msgText = 'Draft campaign is saved.'
        }

        this.$notify({
          title: title,
          text: msgText,
        })

        this.$router.push({ name: 'campaigns' })
      } catch (err) {
        this.errMessage = err.body.message
        this.$notify({
          title: 'Failed to create campaign.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    parseCampaign(campaign) {
      const parsedCampaign = cloneDeep(campaign)

      if (parsedCampaign.scheduling) {
        if (parsedCampaign.scheduling.blastScheduleDate) {
          parsedCampaign.scheduling.blastScheduleDate = chrono.parseDate(
            parsedCampaign.scheduling.blastScheduleDate
          )
        }
      }

      return parsedCampaign
    },
  },
  mounted() {
    this.queryTextUsage()
  },
}
</script>
