<template>
  <div class="row justify-content-center">
    <div class="col=12" v-if="!campaign.type">
      <div class="alert alert-light mt-3" role="alert">
        After completing step one, you'll see some useful settings appear here.
      </div>
    </div>
    <div class="col-12 col-lg-10 col-xl-8">
      <div v-if="campaign.type == 'auto'" class="row justify-content-center">
        <!-- Delay -->
        <div class="col-12 col-lg-6">
          <!-- Public profile -->
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">Delay messages</label>

            <!-- Form text -->
            <small class="form-text text-muted"
              >Instead of sending a message immediately once a customer matches your audience, you
              can setup a delay.</small
            >

            <div class="row">
              <div class="col-auto">
                <!-- Switch -->
                <div class="custom-control custom-checkbox-toggle">
                  <input
                    class="custom-control-input"
                    id="enableDelayToggle"
                    v-model="campaign.scheduling.delay"
                    @change="updateScheduleKey('delay', $event)"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="enableDelayToggle"></label>
                </div>
              </div>
              <div class="col ml-n2">
                <!-- Help text -->
                <small class="text-muted" v-if="!campaign.scheduling.delay"
                  >Messages will send immediately</small
                >

                <!-- Help text -->
                <small class="text-muted" v-if="campaign.enableDelay"
                  >Message will be delayed</small
                >
              </div>
            </div>
            <!-- / .row -->
            <hr v-if="campaign.scheduling.delay" />
            <div class="row col-12" v-if="campaign.scheduling.delay">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <span class="fe fe-clock"></span>&nbsp;Delay sending for
                  </div>
                </div>
                <input
                  type="text"
                  v-model="campaign.scheduling.delayLength"
                  @keyup="updateScheduleKey('delayLength', $event)"
                  value="15"
                  class="form-control form-control-prepended"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ campaign.scheduling.delayUnit }}
                  </button>
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      @click="updateScheduleKey('delayUnit', 'minutes')"
                      href="javascript:;"
                      >minutes</a
                    >
                    <a
                      @click="updateScheduleKey('delayUnit', 'days')"
                      class="dropdown-item"
                      href="javascript:;"
                      >days</a
                    >
                    <a
                      @click="updateScheduleKey('delayUnit', 'weeks')"
                      class="dropdown-item"
                      href="javascript:;"
                      >weeks</a
                    >
                    <a
                      @click="updateScheduleKey('delayUnit', 'months')"
                      class="dropdown-item"
                      href="javascript:;"
                      >months</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- End Date -->
        <div class="col-12 col-lg-6">
          <!-- Allow for additional Bookings -->
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">Automatically end campaign</label>

            <!-- Form text -->
            <small class="form-text text-muted"
              >Enable to stop sending new messages from this campaign on a future date.</small
            >

            <div class="row">
              <div class="col-auto">
                <!-- Switch -->
                <div class="custom-control custom-checkbox-toggle">
                  <input
                    class="custom-control-input"
                    id="endDateToggle"
                    @change="updateScheduleKey('autoEnd', $event)"
                    v-model="campaign.scheduling.autoEnd"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="endDateToggle"></label>
                </div>
              </div>
              <div class="col ml-n2">
                <!-- Help text -->
                <small class="text-muted" v-if="!campaign.scheduling.autoEnd"
                  >Campaign will run indefinitely</small
                >

                <!-- Help text -->
                <small class="text-muted" v-if="campaign.scheduling.autoEnd"
                  >Campaign will end at the specified date.</small
                >
              </div>
            </div>
            <!-- / .row -->
            <hr v-if="campaign.scheduling.autoEnd" />
            <div class="row col-12" v-if="campaign.scheduling.autoEnd">
              <flat-pickr
                v-model="campaign.scheduling.autoEndDate"
                @on-change="onDateChange"
                class="form-control"
                :config="{
                  mode: 'single',
                  dateFormat: 'M j, Y',
                  enableTime: false,
                  altInput: true,
                  altFormat: 'M j, Y',
                  wrap: true,
                  key: 'autoEndDate',
                  minDate: $moment().toDate(),
                  minTime: minTime,
                  maxTime: maxTime,
                }"
                placeholder="Choose a date"
                name="autoEndDate"
              ></flat-pickr>
            </div>
          </div>
        </div>
      </div>
      <div v-if="campaign.type == 'blast'" class="row justify-content-center">
        <!-- Start Date -->
        <div class="col-12">
          <!-- Allow for additional Bookings -->
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">Campaign scheduling</label>

            <!-- Form text -->
            <small class="form-text text-muted">{{
              disableBlastScheduleToggle
                ? 'Unable to send immediately, please select a date in the future. According to TCPA regulations campaigns can only be sent out during the hours of 8:00 AM and 9:00 PM.'
                : 'Enable to send out the campaign on a specific day of your choice, rather than immediately.'
            }}</small>

            <div class="row">
              <div class="col-auto">
                <!-- Switch -->
                <div class="custom-control custom-checkbox-toggle">
                  <input
                    class="custom-control-input"
                    id="blastScheduleToggle"
                    :disabled="disableBlastScheduleToggle"
                    @change="updateScheduleKey('blastSchedule', $event)"
                    v-model="campaign.scheduling.blastSchedule"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="blastScheduleToggle"></label>
                </div>
              </div>
              <div class="col ml-n2">
                <!-- Help text -->
                <small class="text-muted" v-if="!campaign.scheduling.blastSchedule"
                  >Messages will be sent immediately</small
                >

                <!-- Help text -->
                <small class="text-muted" v-if="campaign.scheduling.blastSchedule"
                  >Messages will be sent at the selected time in {{ userTimezone.abbr }}</small
                >
              </div>
            </div>
            <!-- / .row -->
            <hr v-if="campaign.scheduling.blastSchedule" />
            <div class="row col-12" v-if="campaign.scheduling.blastSchedule">
              <flat-pickr
                ref="sinceRef"
                v-model="campaign.scheduling.blastScheduleDate"
                class="form-control"
                :config="{
                  mode: 'single',
                  dateFormat: 'M j, Y at H:i',
                  enableTime: true,
                  time_24hr: false,
                  altInput: true,
                  wrap: true,
                  key: 'blastScheduleDate',
                  minDate: $moment().toDate(),
                  minTime: minTime,
                  maxTime: maxTime,
                  formatDate: this.dateFormatter,
                }"
                placeholder="Choose a date"
                name="blastScheduleDate"
                @on-change="onDateChange"
              ></flat-pickr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('campaign')
export default {
  name: 'CreateCampaignSchedule',
  data() {
    return {
      minTime: '08:00',
      maxTime: '20:59',
      disableBlastScheduleToggle: false,
      abbrs: {
        EST: 'Eastern Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
      },
    }
  },
  computed: {
    ...mapState(['campaign']),
    userTimezone() {
      const zoneName = this.$moment.tz.guess()
      const zoneAbbr = this.$moment.tz(zoneName).zoneAbbr()
      return {
        abbr: this.abbrs[zoneAbbr] || zoneAbbr,
        zoneName: zoneName,
      }
    },
  },
  methods: {
    ...mapMutations(['setSchedulingByKey']),
    updateScheduleKey(key, value) {
      if (value.target) {
        value = value.target.value == 'on' ? value.target.checked : value.target.value
      }
      this.setSchedulingByKey({ key, value: value })
    },
    onDateChange(selectedDates, dateStr, instance) {
      if (selectedDates.length) {
        this.setSchedulingByKey({ key: instance.config.key, value: dateStr })
      }
    },
    dateFormatter(date, format) {
      return this.$moment.tz(date, this.userTimezone.zoneName).format('MMM DD, YYYY h:mm A z')
    },
  },
  mounted() {
    if (
      !this.$moment().isBetween(
        this.$moment(this.minTime, 'hh:mm'),
        this.$moment(this.maxTime, 'hh:mm')
      )
    ) {
      this.updateScheduleKey('blastSchedule', true)
      this.disableBlastScheduleToggle = true
    }
  },
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
}
input.form-control {
  height: calc(2.40625rem + 3px);
}
</style>
