import campaign from '../store/campaign'
import { TEMPLATE_CHUNK_TYPES } from '@/lib/modules/campaignSetups'

export const validationRules = {
  type: (campaign) => {
    if (!campaign.title) {
      return 'Be sure to give your campaign a name.'
    }

    if (!campaign.type) {
      return 'Be sure to choose a campaign type.'
    }
    return false
  },
  audience: (campaign, store) => {
    const { customer, customFilter } = store.state

    if (campaign.type === 'blast') {
      if (campaign.templateChunks) {
        if (customFilter.count === 0) {
          return 'A One-time blast must have an audience of at least one customer.'
        }
      } else {
        if (customer.count === 0) {
          return 'A One-time blast must have an audience of at least one customer.'
        }
      }
    }
    // NOTE: If this is an auto campaign it is okay to create it without any customers currently matching.
  },
  message: (campaign) => {
    // template chunks will only exist in a campaign setup
    if (
      (campaign.templateChunks &&
        !campaign.templateChunks.find((tc) => tc.type === TEMPLATE_CHUNK_TYPES.TEXT).template) ||
      (!campaign.templateChunks && !campaign.messageBody)
    ) {
      return 'Please add a message before continuing.'
    }
    return false
  },
  schedule: (campaign) => {
    // templateChunks and blastScheduleValidated will only exist on a campaign setup
    if (
      (campaign.templateChunks &&
        !(campaign.type === 'auto') &&
        !campaign.blastScheduleValidated) ||
      (!campaign.templateChunks &&
        campaign.scheduling.blastSchedule &&
        !campaign.scheduling.blastScheduleDate)
    ) {
      return 'Campaign schedule date is required.'
    }

    return false
  },
  summary: () => {
    return false
  },
}
