<template>
  <div>
    <div class="row mb-4">
      <div class="col-12">
        <h2 class="text-secondary mt-2 mb-1">
          <span class="text-primary">
            <animated-number
              :value="count"
              :formatValue="parseInt"
              :update="updating"
              :complete="doneUpdating"
              :duration="500"
            />
          </span>
          customers match your audience criteria.
        </h2>
        <span class="text-muted">Adjust your criteria below to narrow your audience.</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CustomersTableCard
          :limit="3"
          :light="true"
          :show-string-filter="false"
          :require-marketing-opt-in="true"
          :campaignSource="true"
          :show-customer-count="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
import CustomersTableCard from '@/components/Modules/Customer/CustomersTableCard/CustomersTableCard'
import { createNamespacedHelpers } from 'vuex'
const CustomerModule = createNamespacedHelpers('customer')
const CampaignModule = createNamespacedHelpers('campaign')

export default {
  name: 'CreateCampaignAudience',
  components: {
    AnimatedNumber,
    CustomersTableCard,
  },
  data: () => {
    return {
      initialized: false,
      isCounting: false,
    }
  },
  methods: {
    ...CustomerModule.mapMutations(['setSkipCount', 'setListFilters']),
    updating() {
      this.isCounting = true
    },
    doneUpdating() {
      this.isCounting = false
    },
  },
  computed: {
    ...CustomerModule.mapState(['count']),
    ...CustomerModule.mapState(['customers']),
    ...CampaignModule.mapState(['campaign']),
  },
  mounted() {
    this.setSkipCount(0)

    if (!this.campaign || (!this.campaign._id && !this.campaign.duplicate)) {
      console.log('reset list filters')
      this.setListFilters({})
    }

    this.initialized = true
  },
}
</script>

<style scoped lang="scss"></style>
