import { isAfter } from 'date-fns'

export const isEditableCampaign = (campaign) => {
  switch (campaign.status) {
    case 'completed':
      return false
    case 'draft':
      return true
    default:
      break
  }

  switch (campaign.type) {
    case 'tag':
    case 'auto':
      return true
    case 'blast':
      const hasBlastSchedule = campaign.scheduling.blastSchedule
      const isFutureBlast = isAfter(campaign.scheduling.blastScheduleDate, new Date())

      return hasBlastSchedule && isFutureBlast
    default:
      return false
  }
}

export const campaignStatus = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  SENDING: 'sending',
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
  DRAFT: 'draft',
}
